/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                var selector = '#menu-primary-menu li';

                var url = window.location.href;
                var target = url.split('/');
                $(selector).each(function(){
                    if($(this).find('a').attr('href')===('/'+target[target.length-1])){
                        $(selector).removeClass('active');
                        $(this).addClass('active');
                    }
                });


                $('.flexslider').flexslider({
                    animation: "slide",
                    direction: "horizontal",
                    slideshow: false,
                    directionNav: false,
                });
                $('.site-sliders').flexslider({
                    animation: "slide",
                    direction: "horizontal",
                    slideshow: true,
                    slideshowSpeed: 10000,
                    controlNav: false,
                    customDirectionNav: $(".nav-controls a")
                });
                $('.caseStudiesSlider').flexslider({
                    controlNav: false,
                    customDirectionNav: $(".custom-navigation a")
                });

                $('.pushnav-toggle').on('click', function () {
                    $('.nav-wrapper').toggleClass('pushed');
                    $('.pushnav-toggle i').toggleClass('fa-bars fa-times');
                });

                $('.featured-block').matchHeight();
                //$('.event-block').matchHeight();
                $('.person').matchHeight();

                $('.hoveredimage').on('mouseover', function(){
                    $('.overlay').show();

                });

                // JavaScript to be fired on all pages

                function add_marker($marker, map) {
                    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map
                    });

                    map.markers.push(marker);

                    if ($marker.html()) {
                        var infowindow = new google.maps.InfoWindow({
                            content: $marker.html()
                        });

                        google.maps.event.addListener(marker, 'click', function () {
                            infowindow.open(map, marker);
                        });
                    }
                }

                function center_map(map) {

                    var bounds = new google.maps.LatLngBounds();

                    $.each(map.markers, function (i, marker) {
                        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                        bounds.extend(latlng);
                    });

                    if (map.markers.length === 1) {
                        map.setCenter(bounds.getCenter());
                        map.setZoom(16);
                    } else {
                        map.fitBounds(bounds);
                    }

                }

                function new_map($el) {
                    var $markers = $el.find('.marker');
                    var args = {
                        zoom: 16,
                        center: new google.maps.LatLng(0, 0),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    };
                    var map = new google.maps.Map($el[0], args);
                    map.markers = [];
                    $markers.each(function () {
                        add_marker($(this), map);
                    });
                    center_map(map);
                    return map;
                }


                var map = null;

                $(document).ready(function () {
                    $('.acf-map').each(function () {
                        map = new_map($(this));
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {

                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    if (document.body.classList.contains('post-type-archive-events')) {


        console.log('post-type-archive-events');

        var page = 2; // initialize page counter
        var loading = false;
        var noMorePosts = false;

        $(window).scroll(function() {
            if( $(window).scrollTop() + $(window).height() > $(document).height() - 2500 && !loading && !noMorePosts) {
                loading = true;
                var data = {
                    action: 'load_more_posts',
                    page: page,
                };
                $.post("https:\/\/archie.org\/wp-admin\/admin-ajax.php", data, function(response) {
                    if(response == '') {
                        noMorePosts = true;
                    } else {
                        console.log(response);
                        $('#events-posts-container').append(response);
                        page++;
                    }
                    loading = false;
                });
            }
        });
    }

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
